'use client';

import { useChatDelete } from '@/hooks/useChatDelete';
import { CHAT_QUERY_VARIABLES } from '@/lib/constants/chatQueryVariables';
import { useChatsQuery } from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { PopupMenu, SecondLevelNavItem, Spinner, SpinnerTheme } from '@unique/component-library';
import { IconDelete, IconEdit } from '@unique/icons';
import { useParams } from 'next/navigation';
import { FC, useEffect } from 'react';
import { useChatRename } from './ChatRenameProvider';

type ChatMenuProps = {
  title?: string;
};

export const ChatMenu: FC<ChatMenuProps> = ({ title = 'Chats' }) => {
  const { id } = useParams();
  const chatsToDelete = useAppSelector((state) => state.chat.chatsToDelete);
  const {
    data: chats,
    isLoading,
    error: chatError,
    mutate,
  } = useChatsQuery(CHAT_QUERY_VARIABLES, { revalidateOnFocus: false });
  const { deleteChat, isDeletingChat } = useChatDelete();
  const { handleClickRename } = useChatRename();

  const getIsOnSingleChatPage = (chatId: string) => id === chatId;

  useEffect(() => {
    if (!id?.length || isLoading) return;

    // If a new chat is created (route changes with new id), revalidate chat menu
    const chatExists = chats?.chats.findIndex((chat) => chat.id === id) > -1;
    if (!chatExists) mutate();
  }, [chats?.chats, id, mutate, isLoading]);

  const chatIdsToDelete = chatsToDelete?.map((chat) => chat.chatId) ?? [];
  return (
    <div className="relative h-full">
      <div className="subtitle-1 flex items-center p-4">
        {title}
        {isLoading && (
          <Spinner theme={SpinnerTheme.LIGHT} wrapperClasses="ml-4 flex items-center" size={16} />
        )}
      </div>

      {!isLoading && !chats?.chats?.length && !chatError && (
        <SecondLevelNavItem
          key="no-chats"
          name="Your past chats will show here"
          isActive={false}
          readOnly={true}
        />
      )}

      {!isLoading && !chatError && (
        <>
          {chats?.chats
            ?.filter((chat) => !chatIdsToDelete.includes(chat.id))
            .map((chat) => (
              <SecondLevelNavItem
                key={chat.id}
                name={chat.title ?? 'Unnamed chat'}
                href={`/${chat.id}`}
                isActive={getIsOnSingleChatPage(chat.id)}
                className="group"
              >
                <div className="ml-auto">
                  <PopupMenu
                    buttonClassesClosed="hidden sm:group-hover:flex"
                    buttonClassesOpened="flex ml-2 sm:group-hover:ml-0"
                    menuItems={[
                      {
                        label: 'Rename',
                        icon: <IconEdit width="18px" height="18px" />,
                        onClick: (event) => handleClickRename(event, chat),
                      },
                      {
                        label: 'Delete',
                        icon: <IconDelete />,
                        isLoading: isDeletingChat,
                        onClick: (event) => deleteChat(event, chat.id),
                      },
                    ]}
                  />
                </div>
              </SecondLevelNavItem>
            ))}
        </>
      )}
    </div>
  );
};
